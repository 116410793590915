.button-none {
    padding: 0;
    border: none;
    background: none;
}

.table-header {
    width: 25%;
    vertical-align: top;
}
