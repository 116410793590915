.light-icon {
  margin-right: 10px !important;
}

.light-icon-min-height {
  min-height: 40px !important;
}

.light-icon-max-height {
  max-height: 50px !important;
}

a.btn.disabled {
  background-color: #116CEF !important;
}
