.custom-max-height-420 {
    max-height: 420px !important;
}

.booklet {
    /*height: calc(100vh - 100px);*/
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch !important;
}

.concept-icon {
    max-height: 120px !important;
}

.light-grey-bg {
    background-color: #f4f4f4;
}

.vertical-column  {
    writing-mode: vertical-rl;
    vertical-align: middle;
    transform: rotate(180deg);
    text-align: center;
    /* max-height: 150px; */
}

.vertical-column-img {
    vertical-align: middle;
    text-align: center;
}