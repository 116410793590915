.custom-max-height-420 {
  max-height: 420px !important;
}

.concept-icon {
  max-height: 120px !important;
}

.light-grey-bg {
  background-color: #f4f4f4;
}

table.column-same-width {
  table-layout: fixed ;
  width: 100% ;
}
.column-same-width > td {
  width: 25% ;
}