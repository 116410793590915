.home-links {
  color: #263238;
}

.display-5 {
  font-size: 3.0rem !important;
  font-weight: 300;
  line-height: 1.2;
}

.display-7 {
  font-size: 1.5rem !important;
  font-weight: 300;
  line-height: 1.2;
}

.padding-4-2-rem {
  padding: 4rem 2rem;
}

.no-left-padding {
  padding-left: unset!important;
}

.rounded-button {
  border-radius: 35px !important;
  border-color: white !important;
  color: white !important;
  min-width: 150px;
}

.light-grey-bg {
  background-color: #f4f4f4;
}

.width-70 {
  width: 70%;
  height: auto;
}

.width-80 {
  width: 80%;
  height: auto;
}

.margin-bttm-25 {
  margin-bottom: 25px !important;
}

margin-top-5 {
  margin-top: 5px !important;
}

.large-font-size {
  font-size: 70px !important;
}

.img-woman-tennis {
    background: url("../../../images/landingpage/Woman_Tennis_Black_3600.jpg") no-repeat; 
    background-size: cover;
}

.img-man-tennis {
  background: url("../../../images/landingpage/Man_Tennis_Player_3600.jpg") no-repeat; 
  background-size: cover;
}

.width-50 {
  width: 70%;
  height: auto;
}

@media (min-width: 576px) {
  .custom-size {
    min-height: 300px !important;
  }

  .width-50 {
    width: 70%;
    height: auto;
  }
}

@media (min-width: 768px) { 
  .custom-size {
    min-height: 600px !important;
  }  
}

.img-court {
  background: url("../../../images/landingpage/Blue_Court.png") no-repeat; 
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center; 
}

.black-bg {
  background-color: black !important;
}

.blue-bg {
  background-color: #48b7e6;
}

.tenniscourtgreen {
  background: url("../../../images/trial/aerial-view-tennis-court-green.jpg")no-repeat center center;
  background-size: cover;
}

.underNav {
  margin-top: -50px;
}

.overlayHome {
  background-color: rgba(255, 255, 255, 0.50)
}

.linespacing {
  line-height: 1.5;
}

.black {
  color: #061B2D !important;
}

.header-margin {
  margin-top: 3rem!important;
}

@media (min-width: 576px) {
  .header-margin {
    margin-top:0px !important;
  }
}
