/*
  Ideally here we should only have styles that apply to the hole app
  selector classes and at rules are a good example
 */

/*
 * At-Rules
 */
 @font-face {
  font-family: 'Apercu';
  src: url('./fonts/apercu_regular_pro.otf');
}

@font-face {
  font-family: 'Apercu-Bold';
  src: url('./fonts/apercu-bold-pro.otf');
}

@font-face {
  font-family: 'Apercu-Mono';
  src: url('./fonts/apercu-mono-pro.otf');
}

@font-face {
  font-family: 'Iosevka';
  src: url('./fonts/iosevka-cc-regular.ttf');
}

@font-face {
  font-family: 'San-Franciso-Display-Regular';
  src: url('./fonts/fonts2/SanFranciscoDisplay-Regular.ttf');
}

@font-face {
  font-family: 'Visby-Round-CF-DemiBold';
  src: url('./fonts/fonts2/Visby-Round-CF-DemiBold.otf');
}

@font-face {
  font-family: 'Visby-Round-CF-ExtraLight';
  src: url('./fonts/fonts2/Visby-Round-CF-ExtraLight.otf');
}

@font-face {
  font-family: 'Visby-Round-CF-Medium';
  src: url('./fonts/fonts2/Visby-Round-CF-Medium.otf');
}

@font-face {
  font-family: 'Visby-Round-CF-Regular';
  src: url('./fonts/fonts2/Visby-Round-CF-Regular.otf');
}

.font-VR-Regular {
  font-family: Visby-Round-CF-Regular !important;
}

.font-VR-Medium {
  font-family: Visby-Round-CF-Medium !important;
}

.font-VR-ExtraLight {
  font-family: Visby-Round-CF-ExtraLight !important;
}

.font-VR-DemiBold {
  font-family: Visby-Round-CF-DemiBold !important;
}

.font-SF-Display-Regular {
  font-family: San-Franciso-Display-Regular !important;
}

.navbar-logo {
  width: 80%;
  height: auto;
}

.margin-left-10 {
  margin-left: 10px;
}

/*
 *
 * Type Selectors
 *
 */
body {
  font-family: Apercu;
  color: #263238;
  font-size: 1em;
  line-height: 1.25em;
  overflow: auto !important;
  padding-right: 0 !important
}

html {
  position: relative;
  min-height: 100%;
}

footer {
  padding-top: 20px;
  padding-bottom: 10px;
  color: #CFD8DC;
  background-color: #90A1A9;
}

h1 {
  font-size: 2em;
  line-height: 1.25em;
}

h2 {
  font-size: 1.625em;
  line-height: 1.15384615em;
}

h3 {
  font-size: 1.375em !important;
  line-height: 1.13636364em;
}

h4 {
  font-size: 1.125em !important;
  line-height: 1.11111111em;
}

hr {
  height: 2px;
  border-top: 2px solid #d5d8d9;
}

a {
  color: #2196F3;
}

a:focus {
  text-decoration: none;
  color: #2196F3;
}

a:hover {
  text-decoration: none;
  color: #263238;
}

/*
 * Class Selectors
 */
.bold {
  font-family: 'Apercu-Bold' !important;
}

.ios {
  font-family: Iosevka;
  color: #7D8488;
}

.mono {
  font-family: Apercu-Mono;
}

.apercu {
  font-family: Apercu;
}

.fontBold {
  font-family: "ApercuProBold" !important;
}

.padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-150 {
  padding-bottom: 150px;
}

.padding-bottom-200 {
  padding-bottom: 150px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-sides-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-sides-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-75 {
  margin-bottom: 75px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.black {
  color: #061B2D;
}

.blue {
  color: rgb(33, 150, 243);
}

.white {
  color: white;
}

.grey {
  color: #69747C;
}

.lightGrey {
  color: #CFD8DC;
}

.goodGreen {
  color: #00C853;
}

.badRed {
  color: #FF3D00;
}

.brandBlue {
  color: #2196F3;
}

.lightGreyBg {
  background-color: #F0F3F4;
}

.grey-bg {
  background-color: #EFEFEF;
}

.fixed-top {
  background-color: white;
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

/* .btn-primary {
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3;
  border-width: 0px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0px -2px 0px 0px #116CEF;
  -moz-box-shadow: inset 0px -2px 0px 0px #116CEF;
  box-shadow:
    inset 0px -2px 0px 0px #116CEF,
}

.btn-primary:hover {
  color: #fff;
  background-color: #116CEF;
  border-color: #116CEF;
  border-width: 0px;
  border-radius: 2px;
} */

/* .btn-secondary {
  color: #fff;
  background-color: #64B5F6;
  border-color: #2196F3;
  border-width: 0px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0px -2px 0px 0px #2196F3;
  -moz-box-shadow: inset 0px -2px 0px 0px #2196F3;
  box-shadow:
    inset 0px -2px 0px 0px #2196F3,
}

.btn-secondary:hover {
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3;
  border-width: 0px;
  border-radius: 2px;
}

.btn-tertiary {
  color: #2196F3;
  border-width: 0px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 2px;
  -webkit-box-shadow: inset 0px 0px 0px 2px #2196F3;
  -moz-box-shadow: inset 0px 0px 0px 2px #2196F3;
  box-shadow:
    inset 0px 0px 0px 2px #2196F3;
}

.btn-tertiary:hover {
  color: #fff;
  background-color: #2196F3;
  border-radius: 2px;
}

.btn:focus,
.btn:active {
  outline: none !important;
} */

.footer {
  margin-top: auto;
  bottom: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  /*place-content: flex-end;*/
}

.colorgraph {
  height: 5px;
  border-top: 0;
  background: #B3E5FC;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(left, #B3E5FC, #B3E5FC 12.5%, #7AC0F8 12.5%, #7AC0F8 25%, #64B5F6 25%, #64B5F6 37.5%, #2196F3 37.5%, #2196F3 50%, #2196F3 50%, #2196F3 62.5%, #64B5F6 62.5%, #64B5F6 75%, #7AC0F8 75%, #7AC0F8 87.5%, #B3E5FC 87.5%, #B3E5FC);
  background-image: -moz-linear-gradient(left, #B3E5FC, #B3E5FC 12.5%, #7AC0F8 12.5%, #7AC0F8 25%, #64B5F6 25%, #64B5F6 37.5%, #2196F3 37.5%, #2196F3 50%, #2196F3 50%, #2196F3 62.5%, #64B5F6 62.5%, #64B5F6 75%, #7AC0F8 75%, #7AC0F8 87.5%, #B3E5FC 87.5%, #B3E5FC);
  background-image: -o-linear-gradient(left, #B3E5FC, #B3E5FC 12.5%, #7AC0F8 12.5%, #7AC0F8 25%, #64B5F6 25%, #64B5F6 37.5%, #2196F3 37.5%, #2196F3 50%, #2196F3 50%, #2196F3 62.5%, #64B5F6 62.5%, #64B5F6 75%, #7AC0F8 75%, #7AC0F8 87.5%, #B3E5FC 87.5%, #B3E5FC);
  background-image: linear-gradient(to right, #B3E5FC, #B3E5FC 12.5%, #7AC0F8 12.5%, #7AC0F8 25%, #64B5F6 25%, #64B5F6 37.5%, #2196F3 37.5%, #2196F3 50%, #2196F3 50%, #2196F3 62.5%, #64B5F6 62.5%, #64B5F6 75%, #7AC0F8 75%, #7AC0F8 87.5%, #B3E5FC 87.5%, #B3E5FC);
}

.img-border {
  border: 2px solid #CFD8DC;
}

.green {
  color: #00C853;
}

.red {
  color: #FF3D00;
}

.maxheightIcon {
  max-height: 90px;
}

.metricIcon {
  margin-right: 10px;
}

.nav-underline {
  border-bottom: solid #E2ECF0;
}

.footer a:hover {
  color: white;
}

.tracking {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.display {
  font-size: 70px;
}

.blueGrey {
  color: #546E7A;
}

.dark-grey {
  color: #90A1A9;
}

.subtitle-grey {
  color: #B1BDC2;
}

@media (max-width: 767px) {
  .display-2 {
    font-size: 5rem;
  }

  h1 {
    font-size: 2rem;
  }
}

@media (min-width:768px) {
  body {
    font-size: 1em;
    line-height: 1.25em;
  }

  h1 {
    font-size: 2em;
    line-height: 1.25em;
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.15384615em;
  }

  h3 {
    font-size: 1.375em !important;
    line-height: 1.13636364em;
  }

  h4 {
    font-size: 1.125em !important;
    line-height: 1.11111111em;
  }
}

@media (min-width:992px) {
  body {
    font-size: 1em;
    line-height: 1.375em;
  }

  h1 {
    font-size: 3em;
    line-height: 1.05em;
  }

  h2 {
    font-size: 2.25em;
    line-height: 1.25em;
  }

  h3 {
    font-size: 1.75em;
    line-height: 1.25em;
  }

  h4 {
    font-size: 1.125em !important;
    line-height: 1.22222222;
  }
}

@media (min-width: 1024px) {
  .boxeffect {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

@include media-breakpoint-up(sm) {
  h1 {
    font-weight: bold;
    font-size: 48px !important;
  }
}

@include media-breakpoint-up(md) {
  h1 {
    font-weight: bold;
    font-size: 48px !important;
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    font-weight: bold;
    font-size: 5rem !important;
  }
}

.with-spacing li:not(:last-child) {
  margin-bottom: 10px;
}
/* .container {
  max-width: 100%
} */
