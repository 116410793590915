.row-friend {
    background-color: #EFEFEF;
    margin-top: 5px;
    margin-bottom: 5px;
}

.row-friend > [class^="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
}

.row-friend .profile-img {
    width: 35px;
    height: 35px;
}

.btn-circle {
    width: 41px !important;
    height: 41px !important;
    padding: 13px 18px !important;
    border-radius: 60px !important;
    font-size: 15px !important;
    text-align: center !important;
}

.btn-circle.btn-accept {
    background: no-repeat center/100% url('../../../../images/social/check@3x.png');
}

.btn-circle.btn-reject {
    background: no-repeat center/100% url('../../../../images/social/reject@3x.png');
}

.friends-lst-header {
    margin-top: 0.5em;
    color: #6D757E;
}
