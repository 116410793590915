.add-friend-bttn-img {
    width: 20px;
    height: auto;
    margin-bottom: 5px;
    margin-left: 5px;
}

.form-container {
    padding-top: 15px;
}

.custom-invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.custom-valid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}
