.responsive-font span {
    font-size: .75em !important;
}

@media (min-width: 992px) {
    .responsive-font span {
        font-size: 1em !important;
    }
    
}
