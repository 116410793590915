.feedback {
  background-color: rgba(6,27,45,.7) !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 2px !important;
  border-width: 0px !important;
  border-color: rgba(6,27,45,1) !important;
  box-shadow: 0 5px 10px rgba(0,0,0,0.19), 1px 2px 2px rgba(0,0,0,0.23) !important;
}
#feedbackbutton {
  position: fixed !important;
  z-index: 100 !important;
  bottom: 30px !important;
  right: 30px !important;
}
