.custom-max-height-420 {
  max-height: 420px !important;
}

.concept-icon {
  max-height: 120px !important;
}

.concept-icon-sm {
  max-height: 80px !important;
}
