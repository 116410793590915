.small-font {
    font-size: 0.75em !important;
}

.blue-font {
    color: #376bfa;
}

.list-horizontal {
    display:inline;
    margin-left: 20px;
}

.footer-icon-font {
    font-size: 1.35em !important;
}

.ungated-footer {
    bottom: 0;
    width: 100%;
}

.no-margin-left {
    margin-left:0px !important;
}

@media (min-width: 992px) {
    .ungated-footer {
        position: absolute !important;
    }
}
