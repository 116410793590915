.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
  background-color: #2196F3 !important;
  color: #ffff !important;
  cursor: pointer !important;
}
.nav-link {
  color: #90A1A9 !important;
}
.nav-item {
  cursor: pointer !important;
}
.blockquote p {
  color: #2196F3;
}
.blockquote footer {
  background-color: white;
}
.blockquote {
  padding: .5rem 1rem;
  border-left: .25rem solid #ECEEEF;
}
.label-max-height {
  max-height: 30px;
}
.media-left {
  padding-right: 10px;
}
.label-header-max-height {
  max-height: 45px;
}
.metricIcon {
  min-height: 40px;
}
