.feeds-container {
  margin-bottom: 15px;
}

/* Social feed */

.social-feed-separated .social-header {
  float: left;
  padding: 0;
}

.social-buttons .like {
  width: auto;
  height: 25px;
}

.social-buttons .comment {
  width: auto;
  height: 25px;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.social-buttons .share {
  width: auto;
  height: 32px;
}

.social-feed-separated .social-header img {
  width: 52px;
  height: 52px;
  border: 1px solid #e7eaec;
}

.social-feed-separated .social-feed-box .social-header {
  padding: 15px 15px 0 15px;
  float: none;
}

.social-feed-box {
  /*padding: 15px;*/
  margin-bottom: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 15px;
  opacity: 1;
}

.article .social-feed-box {
  margin-bottom: 0;
  border-bottom: none;
}

.article .social-feed-box:last-child {
  margin-bottom: 0;
  border-bottom: 1px solid #e7eaec;
}

.article .social-feed-box p {
  font-size: 13px;
  line-height: 18px;
}

.social-action {
  margin: 15px;
}

.social-comment .social-comment {
  margin-left: 45px;
}

.social-body {
  padding-bottom: 15px;
}

.social-footer {
  border-top: 1px solid #e7eaec;
  padding: 10px 15px;
  background: #f9f9f9;
}

.social-footer .social-comment img {
  width: 32px;
  margin-right: 10px;
}

.social-comment:first-child {
  margin-top: 0;
}

.social-comment {
  margin-top: 15px;
}

.social-comment textarea {
  font-size: 12px;
}


.form-control,
.single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}

.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

span.divider {
  width: 0;
  height: 2.5em;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.padding-top-10 {
  padding-top: 10px !important;
}
