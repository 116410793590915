.row-likes {
    background-color: #EFEFEF;
    margin-top: 5px;
    margin-bottom: 5px;
}

.row-likes > [class^="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
}

.row-likes .profile-img {
    width: 35px;
    height: 35px;
}

.social-buttons .like {
    width: auto;
    height: 25px;
}

#modal-reactions .modal-content {
    min-height:300px;
}

@media (min-width: 992px) {
    #modal-reactions .modal-content {
        min-height:500px;
    }
}

.row-comments {
    background-color: #EFEFEF;
    margin-top: 5px;
    margin-bottom: 5px;
}

.row-comments > [class^="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
}

.row-comments .profile-img {
    width: 45px;
    height: 45px;
}

.comments-footer .profile-img {
    width: 45px;
    height: 45px;
}

.comments-footer {
    display: block !important;
}

.comments-footer textarea {
    resize: none !important;
    height: 45px !important;
}

.comments-footer button {
    height: 45px !important;
}

#modal-reactions .LoagindContent {
    max-height: 300px !important;
}
