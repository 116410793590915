.home-links {
  color: #263238;
}

h1 {
  font-size: 40px !important;
  line-height: 70px;
}

.humanaug {
  background: url('../../../images/home/computervision.png') no-repeat center
    center;
  background-size: cover;
}

.underNav {
  margin-top: -50px;
}

.white {
  color: white !important;
}

.linespacing {
  line-height: 1.5;
}

.black {
  color: #061b2d !important;
}

.light-grey-bg {
  background-color: #f4f4f4;
}
