.card {
  margin-bottom: .75rem;
}
.card-header {
  background-color: #E8EEEE;
  padding: .75rem 1.25rem;
  cursor: pointer;
}
.card-body {
  padding: .75rem 1.25rem;
}
