.App {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.nav-link.active {
  color: #1E88E5 !important;
}

.overlap {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.black-bg {
  background-color: black;
}

@media (min-width: 768px) {
  .navbar-padding-1-5-3-rem {
    padding: 1.5rem 3rem !important;
  }
}

/* Reports' floating buttons */
#filter-button, #unit-button {
  display: none !important;
}

@media (min-width: 992px) {
  #filter-button, #unit-button {
    display: inherit !important;
  }
}
