.stats-header {
    background-color: #EFEFEF;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.stats-header p {
    margin-bottom: 0 !important;
}
